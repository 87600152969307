import { json, redirect, type DataFunctionArgs } from '@remix-run/node'
import { Link, useLoaderData, type MetaFunction } from '@remix-run/react'
import { z } from 'zod'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { ErrorList } from '#app/components/forms.tsx'
import { SearchBar } from '#app/components/search-bar.tsx'
import { Badge } from '#app/components/ui/badge.tsx'
import { getUserId } from '#app/utils/auth.server.ts'
import { prisma } from '#app/utils/db.server.ts'
import { cn, getPersonaImgSrc, useDelayedIsPending } from '#app/utils/misc.tsx'

const PersonaSearchResultSchema = z.object({
	id: z.string(),
	username: z.string(),
	bio: z.string(),
	name: z.string().nullable(),
	sexualities: z.string().nullable(),
	traits: z.string().nullable(),
	sexualRoles: z.string().nullable(),
	kinks: z.string().nullable(),
	imageId: z.string().nullable(),
})

const PersonaSearchResultsSchema = z.array(PersonaSearchResultSchema)

export async function loader({ request }: DataFunctionArgs) {
	const searchTerm = new URL(request.url).searchParams.get('search')
	if (searchTerm === '') {
		return redirect('/personas')
	}

	// could we add shared items in here? or seperate underneath?
	// move this into function so that new chat ui can use it? suggested contacts and also suggested personas
	const userId = await getUserId(request)
	const like = `%${searchTerm ?? ''}%`
	const rawPersonas = await prisma.$queryRaw`
		SELECT 
			Persona.id, 
			Persona.name, 
			Persona.bio, 
			User.username, 
			PersonaImage.id AS imageId,
			GROUP_CONCAT(DISTINCT Sexuality.name) AS sexualities,
			GROUP_CONCAT(DISTINCT Trait.name) AS traits,
			GROUP_CONCAT(DISTINCT SexualRole.name) AS sexualRoles,
			GROUP_CONCAT(DISTINCT Kink.name) AS kinks
		FROM Persona
		LEFT JOIN User ON Persona.ownerId = User.id
		LEFT JOIN PersonaImage ON Persona.id = PersonaImage.personaId
		LEFT JOIN _PersonaToSexuality ON Persona.id = _PersonaToSexuality.A
		LEFT JOIN Sexuality ON _PersonaToSexuality.B = Sexuality.name
		LEFT JOIN _PersonaToTrait ON Persona.id = _PersonaToTrait.A
		LEFT JOIN Trait ON _PersonaToTrait.B = Trait.name
		LEFT JOIN _PersonaToSexualRole ON Persona.id = _PersonaToSexualRole.A
		LEFT JOIN SexualRole ON _PersonaToSexualRole.B = SexualRole.name
		LEFT JOIN _KinkToPersona ON Persona.id = _KinkToPersona.B
		LEFT JOIN Kink ON _KinkToPersona.A = Kink.name
		WHERE 
			(Persona.private = false OR (Persona.private = true AND Persona.ownerId = ${userId}))
			AND (
				Persona.name LIKE ${like}
				OR Persona.bio LIKE ${like}
				OR Persona.ethnicity LIKE ${like}
				OR User.username LIKE ${like}
				OR Sexuality.name LIKE ${like}
				OR Trait.name LIKE ${like}
				OR SexualRole.name LIKE ${like}
				OR Kink.name LIKE ${like}
			)
		GROUP BY Persona.id
		ORDER BY (
			SELECT Message.updatedAt
			FROM Message
			WHERE Message.personaId = Persona.id
			ORDER BY Message.updatedAt DESC
			LIMIT 1
		) DESC
		LIMIT 50
	`

	const result = PersonaSearchResultsSchema.safeParse(rawPersonas)
	if (!result.success) {
		return json({ status: 'error', error: result.error.message } as const, {
			status: 400,
		})
	}
	return json({ status: 'idle', personas: result.data } as const)
}

export default function PersonasRoute() {
	const data = useLoaderData<typeof loader>()
	const isPending = useDelayedIsPending({
		formMethod: 'GET',
		formAction: '/personas',
	})

	const BadgeLink = ({ to, children }: { to: string; children: string }) => (
		<Badge variant="default">
			<Link to={to} prefetch="intent">
				{children}
			</Link>
		</Badge>
	)

	if (data.status === 'error') {
		console.error(data.error)
	}
	// console.log('data', data)

	return (
		<div className="container mb-48 mt-36 flex flex-col items-center justify-center gap-6">
			<h1 className="text-h1">
				{' '}
				<span className="font-light">my</span>
				<span className="font-bold">kink</span>
				<span className="font-light">.ai</span> Personas
			</h1>
			<div className="w-full max-w-[700px] ">
				<SearchBar status={data.status} autoFocus autoSubmit />
			</div>
			<main>
				{data.status === 'idle' ? (
					data.personas.length ? (
						<ul
							className={cn(
								'mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2',
								{ 'opacity-50': isPending },
							)}
						>
							{data.personas.map(persona => (
								<li key={persona.id}>
									<Link to={persona.id}>
										<img
											className="aspect-[3/2] w-full rounded-2xl object-cover"
											src={getPersonaImgSrc(persona.imageId)}
											alt=""
										/>
										<h3 className="mt-6 text-lg font-semibold leading-8 text-gray-900">
											{persona.name}
										</h3>
										<p className="text-base leading-7 text-gray-600">
											{persona.kinks &&
												persona.kinks
													.split(',')
													.slice(0, 2)
													.map(role => (
														<BadgeLink
															key={role}
															to={`/personas?search=${role}`}
															children={role}
														/>
													))}
											{persona.sexualities &&
												persona.sexualities
													.split(',')
													.slice(0, 2)
													.map(role => (
														<BadgeLink
															key={role}
															to={`/personas?search=${role}`}
															children={role}
														/>
													))}
											{persona.sexualRoles &&
												persona.sexualRoles
													.split(',')
													.slice(0, 2)
													.map(role => (
														<BadgeLink
															key={role}
															to={`/personas?search=${role}`}
															children={role}
														/>
													))}
											{persona.traits &&
												persona.traits
													.split(',')
													.slice(0, 2)
													.map(role => (
														<BadgeLink
															key={role}
															to={`/personas?search=${role}`}
															children={role}
														/>
													))}
										</p>
										<p className="mt-4 text-base leading-7 text-gray-600">
											{persona.bio}
										</p>
										{/* <ul className="mt-6 gap-x-3">
											<li>Kinks: {persona.kinks}</li>
											<li>Traits: {persona.traits}</li>
											<li>{persona.sexualities}</li>
										</ul> */}
									</Link>
								</li>
							))}
						</ul>
					) : (
						<p>No personas found</p>
					)
				) : data.status === 'error' ? (
					<ErrorList errors={['There was an error parsing the results']} />
				) : null}
			</main>
			{/* <Example></Example> */}
		</div>
	)
}

const people = [
	{
		name: 'Emma Dorsey',
		role: 'Senior Developer',
		imageUrl:
			'http://localhost:3000/resources/persona-images/c7d9d8f9-4872-40e9-ad15-025e2a6fa47b',
		bio: 'Praesentium iure error aliquam voluptas ut libero. Commodi placeat sit iure nulla officiis. Ut ex sit repellat tempora. Qui est accusamus exercitationem natus ut voluptas. Officiis velit eos ducimus.',
		twitterUrl: '#',
		linkedinUrl: '#',
	},
	// More people...
]

export function Example() {
	return (
		<div className="bg-white py-24 md:py-32 lg:py-40">
			<div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-8 gap-y-20 px-6 lg:px-8 xl:grid-cols-3">
				<div className="mx-auto max-w-2xl lg:mx-0">
					<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
						Best of the best
					</h2>
					<p className="mt-6 text-lg leading-8 text-gray-600">
						Kink matched daily
					</p>
				</div>
				<ul className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-8 xl:col-span-2">
					{people.map(person => (
						<li key={person.name}>
							<img
								className="aspect-[3/2] w-full rounded-2xl object-cover"
								src={person.imageUrl}
								alt=""
							/>
							<h3 className="mt-6 text-lg font-semibold leading-8 text-gray-900">
								{person.name}
							</h3>
							<p className="text-base leading-7 text-gray-600">{person.role}</p>
							<p className="mt-4 text-base leading-7 text-gray-600">
								{person.bio}
							</p>
							<ul className="mt-6 flex gap-x-6">
								<li>
									<a
										href={person.twitterUrl}
										className="text-gray-400 hover:text-gray-500"
									>
										<span className="sr-only">Twitter</span>
										<svg
											className="h-5 w-5"
											aria-hidden="true"
											fill="currentColor"
											viewBox="0 0 20 20"
										>
											<path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
										</svg>
									</a>
								</li>
								<li>
									<a
										href={person.linkedinUrl}
										className="text-gray-400 hover:text-gray-500"
									>
										<span className="sr-only">LinkedIn</span>
										<svg
											className="h-5 w-5"
											aria-hidden="true"
											fill="currentColor"
											viewBox="0 0 20 20"
										>
											<path
												fillRule="evenodd"
												d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
												clipRule="evenodd"
											/>
										</svg>
									</a>
								</li>
							</ul>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}

export const meta: MetaFunction<typeof loader> = ({ data, location }) => {
	const searchTerm = new URLSearchParams(location.search).get('search')
	if (!searchTerm || data?.status == 'error' || !data?.personas) {
		return [{ title: `Personas | mykink.ai` }]
	}

	const matches =
		data.personas.length >= 50
			? '50+ matches'
			: data.personas.length === 1
			? '1 match'
			: data.personas.length === 0
			? 'No matches'
			: `${data.personas.length} matches`
	return [{ title: `"${searchTerm}" | ${matches} | mykink.ai` }]
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
